import React from 'react';
import { Grid, Card, CardContent } from '@mui/material';
import { Trans } from 'react-i18next';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { DrivingLicenseTypes, JobTypes, GenderTypes } from '../Job.types';
import { Job } from 'src/DTO/Job.type';

export interface JobDetailDataProps {
  job: Job;
}

export const JobDetailData: React.FC<JobDetailDataProps> = ({ job }) => {
  const shouldShowMaleLabel =
    job?.genderTypes?.length === 1 && job?.genderTypes?.includes(GenderTypes.Male);

  const shouldShowFemaleLabel =
    job?.genderTypes?.length === 1 && job?.genderTypes?.includes(GenderTypes.Female);

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography variant="subtitle2">
                <Trans>jobPage.hoursPerMonth</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job.weeklyHours}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography variant="subtitle2">
                <Trans>generic.location</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job.role.location}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={2} mr={1}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobModal.jobType</Trans>
              </Typography>
              {job.jobType === JobTypes.LongtermJob && (
                <Typography
                  variant="body2"
                  fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                  color={TYPOGRAPHY_COLORS.INFO}>
                  <Trans>jobPage.createJobForm.longTermJob</Trans>
                </Typography>
              )}
              {job.jobType === JobTypes.ShortTermJob && (
                <Typography
                  variant="body2"
                  fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                  color={TYPOGRAPHY_COLORS.INFO}>
                  <Trans>jobPage.createJobForm.shortTermJob</Trans>
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobModal.schedule</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job?.timeSchedule}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={2}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobModal.workerCount</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job?.workerCount}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={2}>
              {job?.genderTypes?.length === 2 && (
                <Typography variant="subtitle2">
                  <Trans>jobPage.createJobForm.girlsAndBoys</Trans>
                </Typography>
              )}
              {shouldShowMaleLabel && (
                <Typography variant="subtitle2">
                  <Trans>jobPage.createJobForm.boys</Trans>
                </Typography>
              )}
              {shouldShowFemaleLabel && (
                <Typography variant="subtitle2">
                  <Trans>jobPage.createJobForm.girls</Trans>
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={2} mr={1}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobForm.startNow</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job?.startNow ? <Trans>generic.yes</Trans> : <Trans>generic.no</Trans>}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobForm.language</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job?.hungarianSkillsNeeded && (
                  <>
                    <Trans>jobPage.createJobModal.hungarian</Trans>,
                  </>
                )}
                {job?.englishSkillsNeeded && (
                  <>
                    <Trans>jobPage.createJobModal.english</Trans>
                  </>
                )}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={2} mr={1}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobForm.documents</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job?.documents}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobForm.extraSkills</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job?.extraSkills}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={2} mr={1}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobForm.drivingLicense</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job?.drivingLicense === DrivingLicenseTypes.Car && (
                  <Trans>jobPage.createJobForm.car</Trans>
                )}
                {job?.drivingLicense === DrivingLicenseTypes.MotorCycle && (
                  <Trans>jobPage.createJobForm.motorCycle</Trans>
                )}
                {job?.drivingLicense === DrivingLicenseTypes.None && (
                  <Trans>jobPage.createJobForm.noLicense</Trans>
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobForm.bonus</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job?.bonus}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={2} mr={1}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobForm.contactPerson</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job?.contactName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobForm.contactPhone</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job?.contactPhone}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={2} mr={1}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobForm.contactEmail</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job?.contactEmail}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobForm.arriveInfo</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job?.arriveInfo}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={2} mr={1}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobForm.equipmentInfo</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job?.equipmentInfo}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography variant="subtitle2">
                <Trans>jobPage.createJobModal.recruitmentType</Trans>
              </Typography>
              <Typography
                variant="body2"
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                color={TYPOGRAPHY_COLORS.INFO}>
                {job?.recruitmentNeeded ? <Trans>generic.yes</Trans> : <Trans>generic.no</Trans>}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
