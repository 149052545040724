import React from 'react';
import { Trans } from 'react-i18next';
import { Card, Grid } from '@mui/material';

import { Avatar } from 'src/components/Avatar';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Typography } from 'src/components/Typography';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import { JOB_STATUS } from 'src/DTO/JobStatus.enum';
import { Job, JobApplication } from 'src/DTO/Job.type';
import { JobOffer } from 'src/DTO/JobOffer.type';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { getWorkerNameWithAge } from 'src/utils/getWorkerNameWithAge';

type WorkForceViewContentProps = {
  openApplicationModal: (worker: WorkerProfile, applicationId: string) => void;
  job: Job;
  setTerminateDialogOpen: (isOpen: boolean) => void;
  setIsInviteModalOpen: (isOpen: boolean) => void;
  openInterviewModal: (worker: WorkerProfile) => void;
  jobOffers: JobOffer[];
  jobApplications?: JobApplication[];
};

export const WorkForceViewContent: React.FC<WorkForceViewContentProps> = ({
  openApplicationModal,
  job,
  setTerminateDialogOpen,
  setIsInviteModalOpen,
  openInterviewModal,
  jobOffers,
  jobApplications,
}) => {
  const shouldShowApplicationsGrid = jobApplications && jobApplications.length > 0;
  const shouldShowWorkersGrid = job.workers && job.workers.length > 0;
  const shouldShowInvitedWorkersGrid = jobOffers && jobOffers.length > 0;

  return (
    <>
      <Box mt={4}>
        <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
          <Trans>jobPage.applicants</Trans>
        </Typography>
      </Box>

      <Box>
        {!shouldShowApplicationsGrid && (
          <Typography
            variant="button"
            color={TYPOGRAPHY_COLORS.TEXT}
            fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
            <Trans>workersScreen.invitedWorkerList.emptyMessage</Trans>
          </Typography>
        )}

        {shouldShowApplicationsGrid && (
          <Card sx={{ overflow: 'scroll' }}>
            <Box width="100%" pt={3} pb={3}>
              <Grid container wrap="nowrap">
                {jobApplications?.map(application => (
                  <Grid
                    key={application.worker.id}
                    item
                    xs={4}
                    sm={3}
                    md={2}
                    mr={1}
                    lg={1}
                    sx={{ flex: '0 0 100%' }}>
                    <Box
                      borderRadius="50%"
                      width="3.625rem"
                      height="3.625rem"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      color="white"
                      mx="auto"
                      border="1px solid grey"
                      onClick={() => openApplicationModal(application.worker, application.id)}
                      sx={{ cursor: 'pointer' }}>
                      <Avatar
                        src={application.worker.imageUrls[application.worker.imageUrls.length - 1]}
                        alt={application.worker.name}
                      />
                    </Box>
                    <Box mt={0.75} textAlign="center" lineHeight={1}>
                      <Typography variant="caption">
                        {getWorkerNameWithAge(application.worker)}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Card>
        )}
      </Box>

      <Box mt={4} mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
            <Trans>jobPage.activeWorkers</Trans>
          </Typography>
        </Box>
        <Box display="flex">
          <Box mr={2}>
            <Button
              variant={BUTTON_VARIANT.CONTAINED}
              type="submit"
              color={BUTTON_COLOR.ERROR}
              disabled={job.status === JOB_STATUS.TERMINATED}
              onClick={() => setTerminateDialogOpen(true)}>
              <Trans>jobPage.workerInvitationRollback</Trans>
            </Button>
          </Box>
          <Box>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.INFO}
              type="submit"
              disabled={job.status === JOB_STATUS.TERMINATED}
              onClick={() => setIsInviteModalOpen(true)}>
              <Trans>workersScreen.inviteWorkers</Trans>
            </Button>
          </Box>
        </Box>
      </Box>

      {!shouldShowWorkersGrid && (
        <Typography
          variant="button"
          color={TYPOGRAPHY_COLORS.TEXT}
          fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
          <Trans>workersScreen.invitedWorkerList.emptyMessage</Trans>
        </Typography>
      )}

      {shouldShowWorkersGrid && (
        <Card sx={{ overflow: 'scroll' }}>
          <Box width="100%" pt={3} pb={3}>
            <Grid container wrap="nowrap">
              {job.workers.map(worker => (
                <Grid
                  key={worker.id}
                  item
                  xs={4}
                  sm={3}
                  md={2}
                  mr={1}
                  lg={1}
                  sx={{ flex: '0 0 100%' }}>
                  <Box
                    borderRadius="50%"
                    width="3.625rem"
                    height="3.625rem"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    color="white"
                    mx="auto"
                    border="1px solid grey"
                    onClick={() => openInterviewModal(worker)}
                    sx={{ cursor: 'pointer' }}>
                    <Avatar src={worker.imageUrls[worker.imageUrls.length - 1]} alt={worker.name} />
                  </Box>
                  <Box mt={0.75} textAlign="center" lineHeight={1}>
                    <Typography variant="caption">{getWorkerNameWithAge(worker)}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Card>
      )}

      <Box mt={4} mb={1}>
        <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
          <Trans>jobPage.invited</Trans>
        </Typography>
      </Box>

      {!shouldShowInvitedWorkersGrid && (
        <Typography
          variant="button"
          color={TYPOGRAPHY_COLORS.TEXT}
          fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
          <Trans>workersScreen.invitedWorkerList.emptyMessage</Trans>
        </Typography>
      )}

      {shouldShowInvitedWorkersGrid && (
        <Card sx={{ overflow: 'scroll' }}>
          <Box width="100%" pt={3} pb={3}>
            <Grid container wrap="nowrap">
              {jobOffers.map(offer => (
                <Grid
                  key={offer.worker.id}
                  item
                  xs={4}
                  sm={3}
                  md={2}
                  mr={1}
                  lg={1}
                  sx={{ flex: '0 0 100%' }}>
                  <Box
                    borderRadius="50%"
                    width="3.625rem"
                    height="3.625rem"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    color="white"
                    mx="auto"
                    border="1px solid grey"
                    onClick={() => openInterviewModal(offer.worker)}
                    sx={{ cursor: 'pointer' }}>
                    <Avatar
                      src={offer.worker.imageUrls[offer.worker.imageUrls.length - 1]}
                      alt={offer.worker.name}
                    />
                  </Box>
                  <Box mt={0.75} textAlign="center" lineHeight={1}>
                    <Typography variant="caption">{getWorkerNameWithAge(offer.worker)}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Card>
      )}
    </>
  );
};
