import React from 'react';
import { useState } from 'react';

// react-router-dom components
import { Link, useHistory } from 'react-router-dom';

// @mui material components
import Checkbox from '@mui/material/Checkbox';

import { Formik, Field, Form } from 'formik';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { FormInput } from 'src/components/FormInputField/FormInput';
import { Button } from 'src/components/Button';

// Authentication layout components
import { SigninLayout } from 'src/components/layouts/SignInLayout';

// Image
import dellaLogo from 'src/assets/images/della-logo-white.png';
import { useTranslation, Trans } from 'react-i18next';
import { RegisterCompanyData, RegisterCompanyPayload } from 'src/api/hooks/useAuth.types';
import { registerFormSchema } from './validationSchemas';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from 'src/components/Button/types';
import { ConfirmDetails } from './components/ConfirmDetails';
import { useLoginAuth, useMe, useRegisterManagerWithCompany } from 'src/api/hooks/useAuth';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { ORG_TYPE } from 'src/DTO/OrganizationType.enum';

const registerFormInit: RegisterCompanyData = {
  businessName: '',
  businessPostCode: '',
  businessCity: '',
  businessAddressLine1: '',
  businessCountry: '',
  businessEmail: '',
  businessVatNumber: '',
  businessNumber: '',
  businessCeoName: '',
  businessPhoneNumber: '',
  password: '',
  confirmPassword: '',
  organizationType: ORG_TYPE.COMPANY,
};

function SignUp() {
  const history = useHistory();
  //  LOCAL STATE
  const [registerFormData, setRegisterFormData] = useState<RegisterCompanyPayload>();
  const [isConfirmView, setIsConfirmView] = useState<boolean>(false);
  const [isSubmitButtonPressed, setIsSubmitButtonPressed] = useState<boolean>(false);
  const [isTOSAccepted, setIsTOSAccepted] = useState<boolean>(false);
  const [isReadAndAcceptPrivacy, setIsReadAndAcceptPrivacy] = useState<boolean>(false);
  const [organizationType, setOrganizatonType] = useState<ORG_TYPE>(ORG_TYPE.COMPANY);

  /* GLOBAL STATE */
  const updateUser = useStoreActions(actions => actions.user.update);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const { t } = useTranslation();

  const handleCheckbox = () => {
    setOrganizatonType(
      organizationType === ORG_TYPE.COMPANY ? ORG_TYPE.STUDENT_ORG : ORG_TYPE.COMPANY,
    );
  };

  const handleSubmitButtonPress = (formData: RegisterCompanyData) => {
    const { confirmPassword, ...submitData } = formData;
    submitData.organizationType = organizationType;
    setRegisterFormData(submitData);
    setIsConfirmView(true);
    setIsSubmitButtonPressed(true);
  };

  const [getMe] = useMe({
    onComplete: res => {
      if (res && res.managerProfile) {
        if (res.invites.length > 0) {
          updateUser({
            ...res.managerProfile,
            isLogedIn: true,
          });

          history.push('/');

          return;
        }

        history.push('/not-invited');

        return;
      }

      history.push('/auth');
    },
  });

  const [startLogin] = useLoginAuth({
    onComplete: res => {
      if (res && res.token) {
        // TODO implement actual valid expiration date for token
        updateUser({
          id: '',
          tokenExpire: new Date(res.tokenExpire || ''),
          token: res.token,
          isLogedIn: false,
        });

        getMe();
      } else {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.error,
          message: t('alerts.registration.error'),
        });
      }
    },
    onError: error => {
      if (error?.response?.status === 403) {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.error,
          message: t('alerts.registration.error'),
        });
      }
    },
  });

  const [registerCompany] = useRegisterManagerWithCompany({
    onComplete: res => {
      if (res && res.isSuccess) {
        startLogin({
          variables: {
            email: registerFormData?.businessEmail || '',
            password: registerFormData?.password || '',
          },
        });
      } else if (res) {
        alert(res.message);
      } else {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.error,
          message: t('alerts.registration.error'),
        });
      }
    },
    onError: err => {
      console.log(err);
    },
  });

  const sendRegisterRequest = () => {
    if (registerFormData) {
      registerCompany({ variables: { ...registerFormData } });
    }
  };

  return (
    <SigninLayout
      title={t('registrationScreen.mainText')}
      description={t('registrationScreen.helperText')}
      illustration={{
        image: dellaLogo,
        title: 'Della Manager',
        description: t('generic.loginUpsell'),
      }}>
      {!isConfirmView && (
        <Formik
          initialValues={registerFormData ? registerFormData : registerFormInit}
          validationSchema={registerFormSchema(t)}
          validateOnBlur
          onSubmit={data => handleSubmitButtonPress(data as RegisterCompanyData)}>
          {({ isValid, dirty }) => (
            <>
              <Form>
                <Box mb={2}>
                  <Field
                    placeholder={t('registrationScreen.email')}
                    name="businessEmail"
                    component={FormInput}
                  />
                </Box>
                <Box mb={2}>
                  <Field
                    placeholder={t('registrationScreen.password')}
                    name="password"
                    type="password"
                    component={FormInput}
                  />
                </Box>
                <Box mb={2}>
                  <Field
                    placeholder={t('registrationScreen.confirmPassword')}
                    name="confirmPassword"
                    type="password"
                    component={FormInput}
                  />
                </Box>
                <Box mb={2}>
                  <Field
                    placeholder={t('registrationScreen.phoneNumber')}
                    name="businessPhoneNumber"
                    component={FormInput}
                  />
                </Box>
                <Box mb={2}>
                  <Field
                    placeholder={t('registrationScreen.nameOfTheBusiness')}
                    name="businessName"
                    component={FormInput}
                  />
                </Box>
                <Box mb={2}>
                  <Field
                    placeholder={t('registrationScreen.representative')}
                    name="businessCeoName"
                    component={FormInput}
                  />
                </Box>
                <Box mb={2}>
                  <Field
                    placeholder={t('registrationScreen.zip')}
                    name="businessPostCode"
                    component={FormInput}
                    inputProps={{ maxLength: 4 }}
                  />
                </Box>
                <Box mb={2}>
                  <Field
                    placeholder={t('registrationScreen.city')}
                    name="businessCity"
                    component={FormInput}
                  />
                </Box>
                <Box mb={2}>
                  <Field
                    placeholder={t('registrationScreen.address')}
                    name="businessAddressLine1"
                    component={FormInput}
                  />
                </Box>
                <Box mb={2}>
                  <Field
                    placeholder={t('registrationScreen.country')}
                    name="businessCountry"
                    component={FormInput}
                  />
                </Box>
                <Box mb={2}>
                  <Field
                    placeholder={t('registrationScreen.vatNumber')}
                    name="businessVatNumber"
                    component={FormInput}
                    inputProps={{ maxLength: 13 }}
                  />
                </Box>
                <Box mb={2}>
                  <Field
                    placeholder={t('registrationScreen.registrationNumber')}
                    name="businessNumber"
                    component={FormInput}
                    inputProps={{ maxLength: 10 }}
                  />
                </Box>

                <Box display="flex" alignItems="center" mb={1}>
                  <Checkbox
                    checked={organizationType === ORG_TYPE.STUDENT_ORG}
                    onChange={handleCheckbox}
                  />

                  <Typography
                    variant="button"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    onClick={handleCheckbox}
                    sx={{ cursor: 'pointer', userSelect: 'none', p: 1 }}>
                    {t('registrationScreen.registerStudentOrg.label1')}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" mb={1}>
                  <Checkbox
                    checked={isTOSAccepted}
                    onChange={() => setIsTOSAccepted(!isTOSAccepted)}
                  />

                  <Typography
                    component="a"
                    variant="button"
                    sx={{ cursor: 'pointer', userSelect: 'none', p: 1 }}
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
                    <Trans
                      i18nKey="registrationScreen.readAndAcceptTOS.label1"
                      components={{
                        a: (
                          <Typography
                            component="a"
                            variant="button"
                            sx={{ cursor: 'pointer', userSelect: 'none' }}
                            href={
                              'https://della-storage-one.de-fra1.upcloudobjects.com/dellahu/della_szoftver.pdf'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
                          />
                        ),
                      }}
                    />
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" mb={2}>
                  <Checkbox
                    checked={isReadAndAcceptPrivacy}
                    onChange={() => setIsReadAndAcceptPrivacy(!isReadAndAcceptPrivacy)}
                  />

                  <Typography
                    component="a"
                    variant="button"
                    sx={{ cursor: 'pointer', userSelect: 'none', p: 1 }}
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
                    <Trans
                      i18nKey="registrationScreen.readAndAcceptPrivacy.label1"
                      components={{
                        a: (
                          <Typography
                            component="a"
                            variant="button"
                            sx={{ cursor: 'pointer', userSelect: 'none' }}
                            href={
                              organizationType === ORG_TYPE.STUDENT_ORG
                                ? 'https://della-storage-one.de-fra1.upcloudobjects.com/dellahu/della_iskolaszovetkezeteknek.pdf'
                                : 'https://della-storage-one.de-fra1.upcloudobjects.com/dellahu/fogado_felek_della_manager.pdf'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
                          />
                        ),
                      }}
                    />
                  </Typography>
                </Box>

                <Box mt={2} mb={4}>
                  <Button
                    fullWidth
                    variant={BUTTON_VARIANT.GRADIENT}
                    color={BUTTON_COLOR.INFO}
                    type="submit"
                    size={BUTTON_SIZE.LARGE}
                    disabled={
                      ((!isValid || !dirty) && !isSubmitButtonPressed) ||
                      !isTOSAccepted ||
                      !isReadAndAcceptPrivacy
                    }>
                    <Trans>registrationScreen.button</Trans>
                  </Button>
                </Box>
                <Box mt={3} textAlign="center">
                  <Typography
                    variant="button"
                    color={TYPOGRAPHY_COLORS.TEXT}
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
                    <Trans>loginScreen.alreadyHaveAccount</Trans>&nbsp;
                    <Typography
                      component={Link}
                      to="/auth"
                      variant="button"
                      color={TYPOGRAPHY_COLORS.INFO}
                      fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
                      textGradient>
                      <Trans>generic.login</Trans>
                    </Typography>
                  </Typography>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      )}

      {isConfirmView && registerFormData && (
        <ConfirmDetails
          registerFormData={registerFormData}
          handleSubmit={sendRegisterRequest}
          setIsConfirmView={setIsConfirmView}
        />
      )}
    </SigninLayout>
  );
}

export { SignUp };
