import React from 'react';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { Avatar } from 'src/components/Avatar';
import { WorkerNameCellProps } from './types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { AVATAR_BG_COLOR, AVATAR_SIZE } from 'src/components/Avatar/types';

function WorkerNameCell({ image = '', name, color = 'dark' }: WorkerNameCellProps) {
  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        <Avatar bgColor={color as AVATAR_BG_COLOR} src={image} alt={name} size={AVATAR_SIZE.XS} />
      </Box>
      <Typography
        variant="caption"
        fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
        color={TYPOGRAPHY_COLORS.TEXT}
        sx={{ lineHeight: 0 }}>
        {name}
      </Typography>
    </Box>
  );
}

export { WorkerNameCell };
