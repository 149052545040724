import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';
import {
  GetInvitableWorkersPayload,
  InviteWorkerPayload,
  RemoveWorkerPayload,
  RemoveWorkerResponse,
  ResendWorkerInvitePayload,
  ResendWorkerInviteResponse,
} from './useWorker.types';

export const useInviteWorkerRequest: BaseHookRequest<WorkerProfile, InviteWorkerPayload> =
  params => {
    const [{ postRequest }, state] = useApi<WorkerProfile>(params);
    const inviteWorker: BaseRequest<InviteWorkerPayload> = details => {
      postRequest<InviteWorkerPayload>('/manager/worker/invite/create', details?.variables);
    };

    return [inviteWorker, state];
  };

export const useGetInvitedWorkerRequest: BaseHookRequest<WorkerProfile[], null> = params => {
  const [{ getRequest }, state] = useApi<WorkerProfile[]>(params);
  const getInvitedWorkers = () => {
    getRequest<InviteWorkerPayload>('/manager/worker/invitedWorkers');
  };

  return [getInvitedWorkers, state];
};

export const useGetInvitableWorkers: BaseHookRequest<WorkerProfile[], GetInvitableWorkersPayload> =
  params => {
    const [{ getRequest }, state] = useApi<WorkerProfile[]>(params);
    const getInvitableWorkers: BaseRequest<GetInvitableWorkersPayload> = details => {
      getRequest(`/manager/worker/invitableWorkers/${details?.variables.jobId}`);
    };
    return [getInvitableWorkers, state];
  };

export const useResendInviteRequest: BaseHookRequest<
  ResendWorkerInviteResponse,
  ResendWorkerInvitePayload
> = params => {
  const [{ postRequest }, state] = useApi<ResendWorkerInviteResponse>(params);
  const resendWorkerInvite: BaseRequest<ResendWorkerInvitePayload> = details => {
    postRequest<ResendWorkerInvitePayload>('/manager/worker/invite/resend', details?.variables);
  };

  return [resendWorkerInvite, state];
};

export const useRemoveWorkerRequest: BaseHookRequest<RemoveWorkerResponse, RemoveWorkerPayload> =
  params => {
    const [{ postRequest }, state] = useApi<RemoveWorkerResponse>(params);
    const removeWorker: BaseRequest<RemoveWorkerPayload> = details => {
      postRequest<RemoveWorkerPayload>('/manager/worker/remove', details?.variables);
    };

    return [removeWorker, state];
  };
