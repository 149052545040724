import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { HomeCard } from './components/HomeCard';
import { DashboardLayout } from 'src/components/layouts/LayoutContainers/DashboardLayout';
import { Footer } from 'src/components/Footer';

import { Globe } from 'src/components/Globe';

import breakpoints from 'src/theme/base/breakpoints';
import { TYPOGRAPHY_TRANSFORM, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { useHistory } from 'react-router-dom';

function Home() {
  const { t } = useTranslation();
  const history = useHistory();
  const { values } = breakpoints;

  return (
    <DashboardLayout>
      <Box>
        <Grid container>
          <Grid item xs={12} lg={7}>
            <Box mb={3} p={1}>
              <Typography
                variant={window.innerWidth < values.sm ? 'h3' : 'h2'}
                textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}
                fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
                <Trans>menu.home</Trans>
              </Typography>
            </Box>

            <Grid container>
              <Grid item xs={12}>
                <Globe
                  display={{ xs: 'none', md: 'block' }}
                  position="absolute"
                  top="10%"
                  right={0}
                  mt={{ xs: -12, lg: 1 }}
                  mr={{ xs: 0, lg: 10 }}
                  canvasStyle={{ marginTop: '3rem' }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box mb={3}>
                  <HomeCard
                    title={t('menu.jobs')}
                    icon="work"
                    action={{ label: t('generic.look'), callback: () => history.push('/job') }}
                  />
                </Box>
                <Box mb={3}>
                  <HomeCard
                    title={t('homeScreen.tabs.workersTabTitle')}
                    icon="account_circle"
                    action={{ label: t('generic.look'), callback: () => history.push('/workers') }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={3}>
                  <HomeCard
                    title={t('homeScreen.tabs.confirmationTabTitle')}
                    icon="confirmation_number"
                    action={{
                      label: t('generic.look'),
                      callback: () => history.push('/confirmations'),
                    }}
                  />
                </Box>
                <Box mb={3}>
                  <HomeCard
                    title={t('homeScreen.tabs.financeTabTitle')}
                    icon="attach_money"
                    action={{ label: t('generic.look'), callback: () => history.push('/finance') }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </DashboardLayout>
  );
}

export { Home };
