import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import { POOL_STATUS } from 'src/DTO/PoolStatus.enum';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useResendInviteRequest } from 'src/api/hooks/useWorker';
import { useStoreActions } from 'src/stores';

type WorkerActionsCellProps = {
  worker: WorkerProfile;
};

export function WorkerActionsCell({ worker }: WorkerActionsCellProps) {
  const history = useHistory();
  const { t } = useTranslation();

  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [resendWorkerInvite] = useResendInviteRequest({
    onComplete: res => {
      if (res) {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('workersScreen.invitedWorkerList.resendInvite.success'),
        });
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workersScreen.invitedWorkerList.resendInvite.error'),
      });
    },
  });

  const handleOpenWorkerProfile = () => {
    history.push({
      pathname: `/worker/${worker.id}`,
      state: { worker },
    });
  };

  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        <Button
          variant={BUTTON_VARIANT.CONTAINED}
          color={BUTTON_COLOR.INFO}
          onClick={handleOpenWorkerProfile}>
          <Trans>workerCard.profile</Trans>
        </Button>
      </Box>
      {worker.poolStatus === POOL_STATUS.PENDING_OFFER && (
        <Button
          variant={BUTTON_VARIANT.CONTAINED}
          color={BUTTON_COLOR.INFO}
          onClick={() => resendWorkerInvite({ variables: { workerId: worker.id } })}>
          <Trans>workerProfile.resend</Trans>
        </Button>
      )}
    </Box>
  );
}
