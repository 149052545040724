import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, Card } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { DashboardLayout } from 'src/components/layouts/LayoutContainers/DashboardLayout';
import { Header } from 'src/pages/Profile/components/Header';
import { Footer } from 'src/components/Footer';
import { WorkerProfileInfoCard } from 'src/components/Cards/WorkerProfileInfoCard';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { useResendInviteRequest } from 'src/api/hooks/useWorker';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useGetInterviewHash } from 'src/api/hooks/useInterview';
import { HashAttributes } from '../Job/components/EmptyJobView';
import { TYPOGRAPHY_TRANSFORM, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { getWorkerNameWithAge } from 'src/utils/getWorkerNameWithAge';
interface WorkerProfileParamsState {
  worker: WorkerProfile;
}

export const WorkerProfilePage: React.FC = () => {
  const {
    state: { worker },
  } = useLocation<WorkerProfileParamsState>();
  const { t } = useTranslation();

  const [hashAttributes, setHashAttributes] = useState<HashAttributes>();
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [resendWorkerInvite, { loading }] = useResendInviteRequest({
    onComplete: res => {
      if (res) {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('workersScreen.invitedWorkerList.resendInvite.success'),
        });
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workersScreen.invitedWorkerList.resendInvite.error'),
      });
    },
  });

  const [getInterviewHash] = useGetInterviewHash({
    onComplete: res => {
      if (res) {
        setHashAttributes(res);
      }
    },
  });

  const handleResendWorkerInvite = useCallback(() => {
    resendWorkerInvite({ variables: { workerId: worker.id } });
  }, []);

  useEffect(() => {
    getInterviewHash();
  }, []);

  return (
    <DashboardLayout>
      <Header
        imageUrls={worker.imageUrls}
        title={getWorkerNameWithAge(worker)}
        subTitle={worker.phoneNumber}
        loading={loading}
        actions={[{ label: t('workerProfile.resend'), action: handleResendWorkerInvite }]}
      />
      <Box mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <WorkerProfileInfoCard worker={worker} />
          </Grid>
          {!!worker.interviewId && hashAttributes && (
            <Grid item xs={12} md={6}>
              <Card>
                <Box pt={2} px={2}>
                  <Typography
                    variant="h6"
                    fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
                    textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
                    <Trans>workerProfile.interview</Trans>
                  </Typography>
                </Box>
                <Box height="400px">
                  <iframe
                    width="100%"
                    height="100%"
                    allowFullScreen
                    src={`https://embed.myinterview.com/player.v3.html?apiKey=xkqnejs448zhbgzqnuqljpm5&hashTimestamp=${hashAttributes.timestamp}&hash=${hashAttributes.hash}&video=${worker.interviewId}&fs=0`}></iframe>
                </Box>
              </Card>
            </Grid>
          )}
        </Grid>
      </Box>
      <Footer />
    </DashboardLayout>
  );
};
