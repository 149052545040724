import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Tooltip,
  CircularProgress,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
} from '@mui/material';
import { Button } from 'src/components/Button';
import { Trans, useTranslation } from 'react-i18next';
import { BUTTON_VARIANT, BUTTON_SIZE, BUTTON_COLOR } from '../Button/types';
import { AVATAR_SHADOW, AVATAR_SIZE, AVATAR_VARIANT } from '../Avatar/types';
import { Avatar } from '../Avatar';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from '../Typography/types';
import { ModalChatGPTProps } from './types';

const ModalChatGPT: React.FC<ModalChatGPTProps> = ({
  shouldOpen,
  textContents,
  imageContents,
  handleClose,
  handleGeneration,
  textGenerationLoading,
  imageGenerationLoading,
}) => {
  const { t } = useTranslation();

  const [tooltipText, setTooltipText] = useState<string>(t('jobPage.textGenerationModal.copy'));

  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text).then(() => {
      console.log(text);
      setTooltipText(t('jobPage.textGenerationModal.copied'));
      setTimeout(() => setTooltipText(t('jobPage.textGenerationModal.copy')), 2000);
    });
  };

  return (
    <Box>
      <Grid item xs={12}>
        <Dialog
          open={shouldOpen}
          onClose={handleClose}
          fullWidth={true}
          keepMounted
          PaperProps={{
            style: {
              width: '100%',
              maxWidth: '750px',
              padding: '10px',
            },
          }}>
          <DialogTitle>
            <Box display="flex" justifyContent="center" sx={{ padding: '0.5rem' }}>
              {' '}
              <Typography fontWeight={TYPOGRAPHY_WEIGHT.REGULAR} sx={{ fontSize: 19 }}>
                <Trans>jobPage.GenerationModal.title</Trans>
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            {(textContents?.[0]?.texts ?? []).map((sectionContent, index) => (
              <Card key={index} variant="outlined" sx={{ marginBottom: '20px' }}>
                <CardHeader
                  title={
                    <Box sx={{ display: 'block', paddingLeft: '10px' }}>
                      <Typography
                        variant="overline"
                        sx={{ fontSize: 12 }}
                        fontWeight={TYPOGRAPHY_WEIGHT.LIGHT}
                        color={TYPOGRAPHY_COLORS.TEXT}>
                        <Trans>jobPage.GenerationModal.version</Trans> {index + 1}:
                      </Typography>
                    </Box>
                  }
                />
                <CardContent>
                  {textGenerationLoading ? (
                    <Box sx={{ display: 'block', marginBottom: '10px' }}>
                      <Typography
                        variant="overline"
                        sx={{ fontSize: 12 }}
                        fontWeight={TYPOGRAPHY_WEIGHT.LIGHT}
                        color={TYPOGRAPHY_COLORS.TEXT}>
                        <Trans>jobPage.GenerationModal.text.generating</Trans>
                        <CircularProgress size={12} color="inherit" />
                      </Typography>
                    </Box>
                  ) : (
                    <Typography
                      variant="subtitle2"
                      fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                      sx={{ fontSize: 14 }}>
                      {sectionContent}
                    </Typography>
                  )}

                  {imageGenerationLoading ? (
                    <Box sx={{ display: 'block', marginBottom: '10px' }}>
                      <Typography
                        variant="overline"
                        sx={{ fontSize: 12 }}
                        fontWeight={TYPOGRAPHY_WEIGHT.LIGHT}
                        color={TYPOGRAPHY_COLORS.TEXT}>
                        <Trans>jobPage.GenerationModal.image.generating</Trans>
                        <CircularProgress size={12} color="inherit" />
                      </Typography>
                    </Box>
                  ) : (
                    <Grid item>
                      <Box mt={2} mb={1}>
                        {imageContents?.length > 0 ? (
                          imageContents.map((imageContent, imgIndex) => (
                            <Link key={imgIndex}>
                              <Tooltip
                                title={
                                  <Trans>jobPage.GenerationModal.image.higherResolution</Trans>
                                }
                                placement="right">
                                <Avatar
                                  alt={`Generated image ${imgIndex + 1}`}
                                  src={imageContent.images[index]}
                                  variant={AVATAR_VARIANT.ROUNDED}
                                  size={AVATAR_SIZE.XXL}
                                  shadow={AVATAR_SHADOW.SM}
                                  onClick={() => {
                                    window.open(
                                      imageContent.images[index],
                                      '_blank',
                                      'noopener noreferrer',
                                    );
                                  }}
                                  sx={{
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                    transition: 'transform 0.4s',
                                    '&:hover': {
                                      transform: 'scale(1.04)',
                                    },
                                  }}
                                />
                              </Tooltip>
                            </Link>
                          ))
                        ) : (
                          <Typography
                            variant="overline"
                            sx={{ fontSize: 14 }}
                            fontWeight={TYPOGRAPHY_WEIGHT.LIGHT}
                            color={TYPOGRAPHY_COLORS.TEXT}>
                            <Trans>jobPage.GenerationModal.image.empty</Trans>
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  )}
                </CardContent>

                <Box
                  display="flex"
                  justifyContent="flex-end"
                  sx={{ padding: '0.5rem 1.5rem 1.5rem' }}>
                  <Tooltip title={tooltipText} arrow>
                    <Button
                      variant={BUTTON_VARIANT.GRADIENT}
                      size={BUTTON_SIZE.SMALL}
                      color={BUTTON_COLOR.INFO}
                      onClick={() => handleCopy(sectionContent as unknown as string)}>
                      <Trans>jobPage.GenerationModal.copy</Trans>
                    </Button>
                  </Tooltip>
                </Box>
              </Card>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              size={BUTTON_SIZE.SMALL}
              color={BUTTON_COLOR.INFO}
              onClick={handleGeneration}>
              {textGenerationLoading ? (
                <CircularProgress size={12} color="inherit" />
              ) : (
                <Trans>jobPage.GenerationModal.reGeneration</Trans>
              )}
            </Button>

            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              size={BUTTON_SIZE.SMALL}
              color={BUTTON_COLOR.INFO}
              onClick={handleClose}>
              <Trans>jobPage.GenerationModal.cancel</Trans>
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  );
};

export default ModalChatGPT;
