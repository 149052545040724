import React from 'react';

import { Avatar } from 'src/components/Avatar';
import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/Button';
import curved0 from 'src/assets/images/curved0.jpg';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from 'src/components/Button/types';
import { Grid, Card } from '@mui/material';
import { AVATAR_SHADOW, AVATAR_SIZE, AVATAR_VARIANT } from 'src/components/Avatar/types';

type Action = {
  label: string;
  action: () => void;
};

type HeaderProps = {
  title: string;
  subTitle: string;
  loading?: boolean;
  actions?: Action[];
  imageUrls?: string[];
};

function Header({ title, subTitle, loading, actions, imageUrls }: HeaderProps) {
  return (
    <Box position="relative">
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }: any) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6),
            )}, url(${curved0})`,
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          overflow: 'hidden',
        }}
      />
      <Card
        sx={{
          backdropFilter: 'saturate(200%) blur(30px)',
          backgroundColor: ({ functions: { rgba }, palette: { white } }: any) =>
            rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }: any) => navbarBoxShadow,
          position: 'relative',
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}>
        <Grid container spacing={3} alignItems="center">
          {imageUrls && imageUrls.length > 0 && (
            <Grid item>
              <Avatar
                src={imageUrls[imageUrls.length - 1]}
                alt={title}
                variant={AVATAR_VARIANT.ROUNDED}
                size={AVATAR_SIZE.XL}
                shadow={AVATAR_SHADOW.SM}
              />
            </Grid>
          )}
          <Grid item flex={1}>
            <Box height="100%" mt={0.5} lineHeight={1}>
              <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                {title}
              </Typography>
              <Typography
                variant="button"
                color={TYPOGRAPHY_COLORS.TEXT}
                fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                {subTitle}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            {!!actions &&
              actions.map(({ label, action }, index) => (
                <Button
                  key={index}
                  variant={BUTTON_VARIANT.GRADIENT}
                  size={BUTTON_SIZE.SMALL}
                  color={BUTTON_COLOR.INFO}
                  disabled={loading}
                  onClick={action}>
                  {!loading && label}
                </Button>
              ))}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}

export { Header };
