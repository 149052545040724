import React, { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid, CircularProgress } from '@mui/material';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { useGetInvitedWorkerRequest } from 'src/api/hooks/useWorker';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { getWorkerNameWithAge } from 'src/utils/getWorkerNameWithAge';
import { DataTable } from 'src/components/DataTable';
import { WorkerStatusCell } from './components/WorkerStatusCell';
import { POOL_STATUS } from 'src/DTO/PoolStatus.enum';
import { getNewestByCreatedDate } from 'src/utils/getNewestByCreatedDate';
import { WorkerActionsCell } from './components/WorkerActionsCell';
import { WorkerNameCell } from './components/WorkerNameCell';

export const WorkersPage: React.FC = () => {
  const [getInvitedWorkers, { loading, data: invitedWorkers }] = useGetInvitedWorkerRequest();
  const { t } = useTranslation();

  const shouldDisplayEmptyMessage = useMemo(
    () => !invitedWorkers || invitedWorkers.length === 0,
    [invitedWorkers],
  );

  useEffect(() => {
    getInvitedWorkers();
  }, []);

  if (loading) {
    return (
      <Grid item xs={12}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  const getJobTitles = (worker: WorkerProfile) => {
    if (!worker.jobs || worker.jobs.length === 0) {
      return undefined;
    }

    if (worker.jobs.length > 1) {
      return `${getNewestByCreatedDate(worker.jobs).role.name} +${worker.jobs.length - 1} ${t(
        'workerCard.more',
      )}`;
    }

    return worker.jobs[0].role.name;
  };

  const getOfferTitles = (worker: WorkerProfile) => {
    if (!worker.offers || worker.offers.length === 0) {
      return undefined;
    }

    if (worker.offers.length > 1 || (worker.jobs && worker.jobs?.length > 1)) {
      return `${getNewestByCreatedDate(worker.offers).job.role.name} +${
        worker.offers.concat((worker.jobs as any) || []).length - 1
      } ${t('workerCard.more')}`;
    }

    return getNewestByCreatedDate(worker.offers).job.role.name;
  };

  const getApplicationTitles = (worker: WorkerProfile) => {
    if (!worker.applications || worker.applications.length === 0) {
      return undefined;
    }

    return getNewestByCreatedDate(worker.applications).job.role.name;
  };

  const getJobsTitle = (worker: WorkerProfile) => {
    const { poolStatus } = worker;

    if (poolStatus === POOL_STATUS.ACTIVE) {
      return getJobTitles(worker);
    }

    if (poolStatus === POOL_STATUS.PENDING_OFFER || poolStatus === POOL_STATUS.WORKER_UNDER_REVIW) {
      return getOfferTitles(worker);
    }

    if (poolStatus === POOL_STATUS.NEW_APPLICATION) {
      return getApplicationTitles(worker);
    }

    return undefined;
  };

  const columns = [
    {
      Header: t('workersScreen.worker'),
      accessor: (worker: WorkerProfile) => (
        <WorkerNameCell
          name={getWorkerNameWithAge(worker)}
          image={worker.imageUrls[worker.imageUrls.length - 1]}
        />
      ),
    },
    { Header: t('workersScreen.phone'), accessor: 'phoneNumber' },
    {
      Header: t('workersScreen.status'),
      accessor: (worker: WorkerProfile) => <WorkerStatusCell poolStatus={worker.poolStatus} />,
    },
    { Header: t('workersScreen.job'), accessor: (worker: WorkerProfile) => getJobsTitle(worker) },
    {
      Header: t('workersScreen.actions'),
      accessor: (worker: WorkerProfile) => <WorkerActionsCell worker={worker} />,
    },
  ];

  return (
    <Grid item xs={12}>
      {shouldDisplayEmptyMessage && (
        <Box display="flex" alignItems="center" pt={3}>
          <Typography>
            <Trans>workersScreen.invitedWorkerList.emptyMessage</Trans>
          </Typography>
        </Box>
      )}
      {!shouldDisplayEmptyMessage && (
        <DataTable
          canSearch
          table={{ columns, rows: invitedWorkers }}
          title={t('workersScreen.invitedWorkerList.invitedWorkerList')}
        />
      )}
    </Grid>
  );
};
